@import '../../scss//variables.scss';

.loginBox {
    width: 608px;
    height: 350px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: absolute;
    top: 30%;
    left: 40%;
}

.loginTitle {
    font-size: 21px;
    font-weight: bold;
    color: $color-iris;
    padding-top: 32px;
    margin-left: 32px;
}

.formContainer {
    margin-top: 70px;
    margin-left: 129px;
    width: 384px;
}

.buttonContainer {
    margin-top: 10%;
    margin-left: 75%;
}

.progress {
    position: relative;
    left: 50%;
    top: 25%
}
// Grey out login
.background {
    width: 100vw;
    height: 120vh;
    background-color: #000000BF;
    position: absolute;
    z-index: 1;
}